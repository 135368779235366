import { default as accounti97vwe9B3UMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue?macro=true";
import { default as index4G2hj6UWJpMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue?macro=true";
import { default as blockchain_45walletGPWipEwTY2Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue?macro=true";
import { default as dedicated_45addresslXAj7qC4f4Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue?macro=true";
import { default as optionsk3qkic4NFSMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue?macro=true";
import { default as indexg8QcAvdLi9Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue?macro=true";
import { default as binanceOPFMRDgfeiMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue?macro=true";
import { default as _91network_93gTL7Bgp37pMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue?macro=true";
import { default as indexVsjBVlKEoDMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue?macro=true";
import { default as brlau9EKwuMeHqMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue?macro=true";
import { default as coinbase_walletyfJpO6UYTDMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue?macro=true";
import { default as coinbasehsgh6rYKI1Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue?macro=true";
import { default as eurvs4LQILv7XMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue?macro=true";
import { default as forexCJsoXprBQ9Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue?macro=true";
import { default as gbphykOCxuSkOMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue?macro=true";
import { default as international_bankIjXp5b3h90Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue?macro=true";
import { default as internationalmB1euUuLGaMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue?macro=true";
import { default as krakenzoG8hyP3WaMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue?macro=true";
import { default as ngn692NEPDBpzMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue?macro=true";
import { default as usTm7G0VpzpkMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue?macro=true";
import { default as optionstr3jFTj4QRMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue?macro=true";
import { default as rampslk3jZcr4YvMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue?macro=true";
import { default as coinbaseB4htZuUfd2Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue?macro=true";
import { default as verify_45walletVTHdl9JFkiMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue?macro=true";
import { default as _91uuid_93a0Jm0EsrwgMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93zmQdrPCu0UMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as loginuJefEd29PtMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue?macro=true";
import { default as registerlbZW6VV8oGMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue?macro=true";
import { default as blockedgBFY9xsVNZMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue?macro=true";
import { default as classificationrvOmsuS7AaMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue?macro=true";
import { default as indexZWUDRWl8sBMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue?macro=true";
import { default as indexJWYN5Z1zsPMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue?macro=true";
import { default as dashboardfGTT1Cw35yMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue?macro=true";
import { default as developer7ithAzKHbRMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue?macro=true";
import { default as pay_scheduleGR8e05W8QLMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue?macro=true";
import { default as indexDQmyOHqtf4Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue?macro=true";
import { default as _91accountId_93Z5ZpBThuj9Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue?macro=true";
import { default as deposit_45usBaXFV9WJu7Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue?macro=true";
import { default as depositrcpZEDjGD3Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue?macro=true";
import { default as _91accountId_93h6a1xasoSDMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue?macro=true";
import { default as riseidMIk5FxmqBxMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue?macro=true";
import { default as fund290TPNA57UMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue?macro=true";
import { default as indexQvhyys10KBMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue?macro=true";
import { default as _91code_93K74QqsllkfMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue?macro=true";
import { default as _91id_93w50nthNuwVMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue?macro=true";
import { default as indexvzn3b37LbQMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue?macro=true";
import { default as index7VxllGHzrlMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue?macro=true";
import { default as jwtYEE60yVGG5Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue?macro=true";
import { default as indexPZ2FFOuyIsMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue?macro=true";
import { default as teamh3OElwQ1JtMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue?macro=true";
import { default as indexnQT1BzFTm9Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue?macro=true";
import { default as onboarding5R6sGlJ5JeMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue?macro=true";
import { default as hourlykJgsGRzF81Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue?macro=true";
import { default as instantZWlApP10RDMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue?macro=true";
import { default as milestoneLp3c0jEionMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue?macro=true";
import { default as once5kP0JtyWT3Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue?macro=true";
import { default as recurringFHp5bmPL2aMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue?macro=true";
import { default as _91userId_93qi2qnP5tZ4Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue?macro=true";
import { default as csvZx8GZaOK1GMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue?macro=true";
import { default as indexazN9s85nLpMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue?macro=true";
import { default as indextEZS7xlCRCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue?macro=true";
import { default as managementTi0j7DPEAEMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue?macro=true";
import { default as _91id_93fMfDY6yn9fMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue?macro=true";
import { default as _91id_932nVk6NHPtRMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue?macro=true";
import { default as _91psId_93vvH2uy9KJsMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue?macro=true";
import { default as indexdlvHWW4r3LMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue?macro=true";
import { default as transaction_45historyaLCfOdhpWnMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue?macro=true";
import { default as playgroundg5XeOQyK8SMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue?macro=true";
import { default as businessEwVGeQSUUEMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue?macro=true";
import { default as indexq1GQEXntcUMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue?macro=true";
import { default as referralKEmiIFqzeFMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue?macro=true";
import { default as riseid_45erc20_45withdrawYqylWcojrUMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue?macro=true";
import { default as riseida2YxziEO3FMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue?macro=true";
import { default as securityW4A5FuggGEMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue?macro=true";
import { default as sign_45documentf34FX3bUOaMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue?macro=true";
import { default as _2fab8gq1APDObMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue?macro=true";
import { default as callbackbJAirFvthBMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue?macro=true";
import { default as code9jNyig8NTTMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue?macro=true";
import { default as indexcm3BF7ynU5Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue?macro=true";
import { default as sign_45outR4Niqq3bTMMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue?macro=true";
import { default as code4T13jlQVsnMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue?macro=true";
import { default as indexRchBBRj869Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue?macro=true";
import { default as waitingTmiwzb6ZjKMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue?macro=true";
import { default as indexsHkdSE9qYjMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue?macro=true";
import { default as optionsblCEdUm69qMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue?macro=true";
import { default as index5zDYuJ2bpKMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue?macro=true";
import { default as importX00r74SIrcMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue?macro=true";
import { default as indexsF1xYM9hy9Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue?macro=true";
import { default as batch_warmed_lighthw2azaV4YrMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue?macro=true";
import { default as batch_warmedBTTeGsSF9YMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue?macro=true";
import { default as directmZy3Te46iCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue?macro=true";
import { default as sumsubxJPK0YkeYzMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue?macro=true";
import { default as warmed_lightsZ5LXOT1zPMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue?macro=true";
import { default as warmednWGyenfH1DMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue?macro=true";
import { default as inviteRmnIWkP6OgMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue?macro=true";
import { default as indexCezVR2jmg4Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue?macro=true";
import { default as _91accountId_93n319As9VAhMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue?macro=true";
import { default as _91accountId_93iy0fM1C36YMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue?macro=true";
import { default as withdrawYJcALZlffNMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accounti97vwe9B3UMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding",
    path: "/accounts/add-funding",
    meta: index4G2hj6UWJpMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options",
    path: "/accounts/add-funding/options",
    meta: optionsk3qkic4NFSMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-funding-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options-dedicated-address",
    path: "dedicated-address",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw",
    path: "/accounts/add-withdraw",
    meta: indexg8QcAvdLi9Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options",
    path: "/accounts/add-withdraw/options",
    meta: optionstr3jFTj4QRMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-withdraw-options-binance",
    path: "binance",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet-network",
    path: "blockchain-wallet/:network()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-brla",
    path: "brla",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase_wallet",
    path: "coinbase_wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-eur",
    path: "eur",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-forex",
    path: "forex",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-gbp",
    path: "gbp",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international_bank",
    path: "international_bank",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international",
    path: "international",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-kraken",
    path: "kraken",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-ngn",
    path: "ngn",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-us",
    path: "us",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw-ramps",
    path: "/accounts/add-withdraw/ramps",
    meta: rampslk3jZcr4YvMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-coinbase",
    path: "/accounts/add/callback/coinbase",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-verify-wallet",
    path: "/accounts/add/callback/verify-wallet",
    meta: verify_45walletVTHdl9JFkiMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "blocked",
    path: "/blocked",
    meta: blockedgBFY9xsVNZMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-classification",
    path: "/clients/:id()/classification",
    meta: classificationrvOmsuS7AaMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue").then(m => m.default || m)
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: indexZWUDRWl8sBMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexJWYN5Z1zsPMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardfGTT1Cw35yMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "developer",
    path: "/developer",
    meta: developer7ithAzKHbRMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: "documents-payschedule-pay_schedule",
    path: "/documents/:payschedule()/pay_schedule",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexDQmyOHqtf4Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "fund",
    path: "/fund",
    meta: fund290TPNA57UMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-dedicated-address-accountId",
    path: "dedicated-address/:accountId()",
    meta: _91accountId_93Z5ZpBThuj9Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit-us",
    path: "deposit-us",
    meta: deposit_45usBaXFV9WJu7Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit",
    path: "deposit",
    meta: depositrcpZEDjGD3Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue").then(m => m.default || m)
  },
  {
    name: "fund-external-account-accountId",
    path: "external-account/:accountId()",
    meta: _91accountId_93h6a1xasoSDMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-riseid",
    path: "riseid",
    meta: riseidMIk5FxmqBxMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexQvhyys10KBMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add-id",
    path: "/invoices/add/:id()",
    meta: _91id_93w50nthNuwVMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add",
    path: "/invoices/add",
    meta: indexvzn3b37LbQMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: index7VxllGHzrlMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    meta: jwtYEE60yVGG5Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create",
    path: "/management/company/create",
    meta: indexPZ2FFOuyIsMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create-team",
    path: "/management/company/create/team",
    meta: teamh3OElwQ1JtMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue").then(m => m.default || m)
  },
  {
    name: "management-company",
    path: "/management/company",
    meta: indexnQT1BzFTm9Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboarding5R6sGlJ5JeMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId",
    path: "/payments/:userId()",
    meta: _91userId_93qi2qnP5tZ4Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "payments-userId-hourly",
    path: "hourly",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-instant",
    path: "instant",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-milestone",
    path: "milestone",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-once",
    path: "once",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-recurring",
    path: "recurring",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-batch-csv",
    path: "/payments/batch/csv",
    meta: csvZx8GZaOK1GMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "payments-batch",
    path: "/payments/batch",
    meta: indexazN9s85nLpMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    meta: indextEZS7xlCRCMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management",
    path: "/payments/management",
    meta: managementTi0j7DPEAEMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-fund-id",
    path: "/payments/receipts/fund/:id()",
    meta: _91id_93fMfDY6yn9fMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-payment-id",
    path: "/payments/receipts/payment/:id()",
    meta: _91id_932nVk6NHPtRMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets-psId",
    path: "/payments/timesheets/:psId()",
    meta: _91psId_93vvH2uy9KJsMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets",
    path: "/payments/timesheets",
    meta: indexdlvHWW4r3LMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-transaction-history",
    path: "/payments/transaction-history",
    meta: transaction_45historyaLCfOdhpWnMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "profile-business",
    path: "/profile/business",
    meta: businessEwVGeQSUUEMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexq1GQEXntcUMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: referralKEmiIFqzeFMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "riseid-erc20-withdraw",
    path: "/riseid-erc20-withdraw",
    meta: riseid_45erc20_45withdrawYqylWcojrUMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue").then(m => m.default || m)
  },
  {
    name: "riseid",
    path: "/riseid",
    meta: riseida2YxziEO3FMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue").then(m => m.default || m)
  },
  {
    name: "security",
    path: "/security",
    meta: securityW4A5FuggGEMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "sign-document",
    path: "/sign-document",
    meta: sign_45documentf34FX3bUOaMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-callback",
    path: "/sign-in/callback",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outR4Niqq3bTMMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingTmiwzb6ZjKMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: indexsHkdSE9qYjMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-options",
    path: "/subscription/options",
    meta: optionsblCEdUm69qMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue").then(m => m.default || m)
  },
  {
    name: "talent-id",
    path: "/talent/:id()",
    meta: index5zDYuJ2bpKMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-import",
    path: "/talent/import",
    meta: importX00r74SIrcMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue").then(m => m.default || m)
  },
  {
    name: "talent",
    path: "/talent",
    meta: indexsF1xYM9hy9Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite",
    path: "/talent/invite",
    meta: inviteRmnIWkP6OgMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue").then(m => m.default || m),
    children: [
  {
    name: "talent-invite-batch_warmed_light",
    path: "batch_warmed_light",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-batch_warmed",
    path: "batch_warmed",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-direct",
    path: "direct",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-sumsub",
    path: "sumsub",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed_light",
    path: "warmed_light",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed",
    path: "warmed",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexCezVR2jmg4Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "withdraw",
    path: "/withdraw",
    meta: withdrawYJcALZlffNMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "withdraw-blockchain-accountId",
    path: "blockchain/:accountId()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "withdraw-wire-accountId",
    path: "wire/:accountId()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue").then(m => m.default || m)
  }
]
  }
]